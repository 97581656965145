import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import BookingReducer from './reducers/bookingReducer';
import { i18nReducer } from 'react-redux-i18n';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['bookingReducer', 'i18n']
};

const rootReducer = combineReducers({
  // add resucer here
  bookingReducer: BookingReducer,
  i18n: i18nReducer
});

export { rootPersistConfig, rootReducer };
