import {
  SET_BOOKING_SERVICE_DETAILS,
  SET_BOOKING_SPECIALIST_DETAILS,
  SET_BOOKING_DATE_TIME_SLOT,
  SET_INIT_ITEM,
  REMOVE_BOOKING_ITEM,
  SET_CUSTOMER_DETAILS,
  SET_SHOP_DETAILS,
  SET_CATEGORIES,
  SET_STAFFS,
  SET_DATA_SHOP,
  SET_BOOKING_DETAILS,
  SET_LOADING,
  SET_ARRAY_SERVICE_DETAILS,
  SET_ARRAY_ORDER_ATTACHMENTS,
  CLEAR_BOOKING,
  CLEAR_ORDER_ATTACHMENT,
  GET_TIME_SLOT,
  CLEAR_INIT,
  SET_DATA_BOOKING_BY_ID
} from "./ActionTypes";

const INITIAL_STATE = {
  serviceDetails: {
    id_service: "",
    active: false,
    duration: "",
    featured: false,
    service_name: "",
    price: "",
    special_price: "",
    type: "",
    staff_ids: [],
  },
  orderAttachmentDetails: {
    image: null
  },
  arrayServiceDetails: [],
  arrayOrderAttachments: [],
  specialist: {
    id_specialist: "",
    avatar: "",
    job_title: "",
    member_id: 0,
    staff_name: "",
    selected: true
  },
  dateTimeSlot: {
    scheduled_date: "",
    scheduled_time: "",
  },
  initItem: 1,
  customerDetails: {
    first_name: "",
    email: "",
    phone_numnber: ""
  },
  shopDetails: {
    address: "",
    banner: "",
    description: "",
    name: "",
    phone: "",
    payment_attachment: false,
    attachment_note: "",
  },
  categories: [],
  staffs: [],
  serviceItems: [],
  availabilities: [],
  loading: false,
  confirmBookingDetails: {
    bookingId: "",
    name: ""
  },
  timeSlotService: [],
  serviceDetailsConfirm : []
};

const BookingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_BOOKING_SERVICE_DETAILS: {
      return {
        ...state,
        serviceDetails: {
          id_service: action.payload.id,
          service_name: action.payload.name,
          price: action.payload.price,
          active: action.payload.active,
          special_price: action.payload.special_price,
          duration: action.payload.duration,
          terms: action.payload.terms,
          staff_ids: action.payload.staff_ids,
        }
      };
    }
    case SET_BOOKING_SPECIALIST_DETAILS: {
      return {
        ...state,
        specialist: {
          id_specialist: action.payload.id,
          avatar: action.payload.avatar,
          staff_name: action.payload.name,
          member_id: action.payload.member_id,
          job_title: action.payload.job_title
        },
      };
    }
    case SET_BOOKING_DATE_TIME_SLOT: {
      const newArrayServiceDetails = [...state.arrayServiceDetails];
      if (state.initItem > newArrayServiceDetails.length) {
        newArrayServiceDetails.push({ ...state.serviceDetails, ...state.specialist, ...action.payload });
      } else {
        newArrayServiceDetails[state.initItem - 1] = { ...state.serviceDetails, ...state.specialist, ...action.payload };
      }
      return {
        ...state,
        dateTimeSlot: action.payload,
        arrayServiceDetails: newArrayServiceDetails
      };
    }
    case SET_INIT_ITEM: {
      return {
        ...state,
        initItem: state.arrayServiceDetails.length + 1
      }
    }
    case REMOVE_BOOKING_ITEM: {
      const newArrayServiceDetails = [...state.arrayServiceDetails];
      newArrayServiceDetails.splice(action.payload, 1)
      return {
        ...state,
        arrayServiceDetails: newArrayServiceDetails
      }
    }
    case SET_CUSTOMER_DETAILS: {
      return {
        ...state,
        customerDetails: action.payload
      }
    }
    case SET_SHOP_DETAILS: {
      return {
        ...state,
        shopDetails: action.payload
      }
    }
    case SET_CATEGORIES: {
      return {
        ...state,
        categories: action.payload
      }
    }
    case SET_STAFFS: {
      return {
        ...state,
        staffs: action.payload
      }
    }
    case SET_DATA_SHOP: {
      return {
        ...state,
        categories: action.payload.categories,
        staffs: action.payload.staff,
        serviceItems: action.payload.items,
        availabilities: action.payload.availabilities,
        shopDetails: action.payload.shop_details,
        loading: false
      }
    }
    case SET_BOOKING_DETAILS: {
      return {
        ...state,
        confirmBookingDetails: {
          bookingId: action.payload.booking_id,
          name: action.payload.name
        }
      }
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }
    case SET_ARRAY_SERVICE_DETAILS: {
      const newArrayServiceDetails = state.arrayServiceDetails.map(item2 => {
        const matchingItem1 = action.payload.find(item1 => item1.id === item2.id_service);
        if (matchingItem1) {
          return { ...item2, service_name: matchingItem1.name, duration: matchingItem1.duration };
        }
        return item2;
      });
      return {
        ...state,
        arrayServiceDetails: newArrayServiceDetails
      }
    }
    case SET_ARRAY_ORDER_ATTACHMENTS: {
      return {
        ...state,
        arrayOrderAttachments: action.payload
      }
    }
    case CLEAR_BOOKING: {
      return {
        ...state,
        arrayServiceDetails: []
      }
    }
    case CLEAR_ORDER_ATTACHMENT: {
      return {
        ...state,
        arrayOrderAttachments: []
      }
    }
    case GET_TIME_SLOT: {
      return {
        ...state,
        timeSlotService: action.payload
      }
    }
    case CLEAR_INIT: {
      return {
        ...state,
        initItem: 1
      }
    }
    case SET_DATA_BOOKING_BY_ID: {
      return {
        ...state,
        serviceDetailsConfirm: action.payload
      }
    }
    default:
      return state;
  }
};

export default BookingReducer;
