export const SET_BOOKING_SERVICE_DETAILS = 'SET_BOOKING_SERVICE_DETAILS';
export const SET_BOOKING_SPECIALIST_DETAILS = 'SET_BOOKING_SPECIALIST_DETAILS';
export const SET_BOOKING_DATE_TIME_SLOT = 'SET_BOOKING_DATE_TIME_SLOT';
export const REMOVE_BOOKING_ITEM = 'REMOVE_BOOKING_ITEM';
export const SET_INIT_ITEM = 'SET_INIT_ITEM';
export const SET_CUSTOMER_DETAILS = 'SET_CUSTOMER_DETAILS';
export const SET_SHOP_DETAILS = 'SET_SHOP_DETAILS';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_STAFFS = "SET_STAFFS";
export const SET_DATA_SHOP = "SET_DATA_SHOP";
export const SET_BOOKING_DETAILS = "SET_BOOKING_DETAILS";
export const SET_LOADING = "SET_LOADING";
export const SET_ARRAY_SERVICE_DETAILS = "SET_ARRAY_SERVICE_DETAILS";
export const SET_ARRAY_ORDER_ATTACHMENTS = "SET_ARRAY_ORDER_ATTACHMENTS";
export const CLEAR_BOOKING = "CLEAR_BOOKING";
export const CLEAR_ORDER_ATTACHMENT = "CLEAR_ORDER_ATTACHMENT";
export const GET_TIME_SLOT = "GET_TIME_SLOT";
export const CLEAR_INIT = "CLEAR_INIT";
export const SET_DATA_BOOKING_BY_ID = "SET_DATA_BOOKING_BY_ID";
