export const SUBCATEGORY_SERVICES = ["all", "message", "nail", "hair", "package"]
export const TOKEN_ACCOUNT = "test-account-2"
export const LOCALE = {
    EN: 'en',
    TH: 'th'
}
export const URL = {
    NOT_FOUND: '404',
    SHOP: ':slug',
    SELECT_SERVICE: 'select-service',
    SELECT_SPECIALIST: 'select-specialist',
    SELECT_TIMESLOT: 'select-timeslot',
    CUSTOMER_DETAIL: 'customer-detail',
    REVIEW_BOOKING_DETAIL: 'review-booking-detail',
    THANKYOU_BOOKING: 'thankyou-booking',
    CANCEL_BOOKING: 'cancel-booking'
}